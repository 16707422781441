<template>
<div class="main-nuevaddjj">
  <div class="titulo">Nueva declaración jurada</div>

  <label for="anio">Año</label>&nbsp;
  <input type="number" id="anio" class="input-field" v-model="anio">&nbsp;
  <button class="button" @click="iniciar">Iniciar Presentación</button>

  <div v-if="poseePresentacion" class="rectificar-section">
    <p>Ya existe una presentación realizada, ¿desea rectificarla?</p>
    <select v-model="rectificar" @change="updateData">
    <option selected>NO</option>
    <option>SI</option>
    </select>&nbsp;
    <button v-if="rectificar == 'NO'" class="button" @click="ver">Ver Presentacion</button>
    <button v-if="rectificar == 'SI'" class="button" @click="rectificarPresentacion">Rectificar</button>
  </div>
  <br>

  {{error}}
</div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import axios from 'axios';
import { url } from '../config.js';

const props = defineProps({
  usuario: {
    type: Object,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['onNuevaDDJJ']);

const loading = ref(false);
const poseePresentacion = ref(false);
const rectificar = ref('NO');
const error = ref("");
let anio = ref('');
const data = ref([]);

onMounted(async () => {
})

async function iniciar() {
  console.log('iniciar');

  try {

    loading.value = true;
    error.value = '';

    //Validate year
    const currentYear = new Date().getFullYear();
    const validYearRangeStart = currentYear - 6;
    const validYearRangeEnd = currentYear;

    if (isNaN(anio.value) || anio.value < validYearRangeStart || anio.value > validYearRangeEnd) {
      loading.value = false;
      error.value = 'Año debe estar entre ' + validYearRangeStart + ' y ' + validYearRangeEnd;
      return;
    }

    const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.token
        };
    const payload = { anio: anio.value };

    let res = await axios.post(url+'/ddjj/nuevaPresentacion/'+anio.value, payload, {headers: headers});
    loading.value = false;
    
    if (res.data.estado == 'Borrador') {
      console.log('Borrador');

      emit('onNuevaDDJJ', res.data);
      return;
    }
    
    data.value = res.data;
    poseePresentacion.value = true;

  } catch(e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }    
}

async function rectificarPresentacion() {
  loading.value = true;
  try {
    const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.token
        };
    const payload = { anio: anio.value };

    let resRectificar = await axios.post(url + '/ddjj/rectificarPresentacion/' + anio.value + '/' + (data.value.presentacion + 1), payload, {headers: headers});
    console.log(resRectificar);

    loading.value = false;
    emit('onNuevaDDJJ', resRectificar.data);
  } catch (e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }
}

async function ver() {
  emit('onNuevaDDJJ', data.value);
}

</script>

<style>
.main-nuevaddjj {
  color: white;
  padding: 20px;
}
.titulo {
  font-size: x-large;
  color: white;
  margin-bottom: 20px;
}
.form-container {
  margin-bottom: 20px;
  padding: 5px;
}
.input-field {
  padding: 8px;
  margin-bottom: 10px;
  background-color: white;
}
.button {
  background-color: white;
  color: black;
  border: 2px solid #000000;
  padding: 8px 16px;
  cursor: pointer;
}
.rectificar-section {
  margin-top: 20px;
}
</style>
