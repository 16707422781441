export const headerDummy = {cc: '', cuit: '', razon_social: '', rubro: '', anio: '', tipo: '', estado: ''};
export const datosContribuyenteDummy = {
    domicilio_habilitacion: {calle: '',numero: '',piso: '',dpto: '',cod_postal: '',localidad: '',partido: ''},
    domicilio_fiscal: {calle: '',numero: '',local: '',piso: '',dpto: '',cod_postal: '',localidad: '',telefono: '',email: ''}
}
export const determinacionTasaDummy = {
  tasas_rubros: [
    {"orden": "1","mes": "ENERO", "rubro": "","alicuota": null,"ingresos": null}
  ],
  tasas: [
    {"orden": "1","mes": "ENERO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "2","mes": "FEBRERO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "3","mes": "MARZO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "4","mes": "ABRIL","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "5","mes": "MAYO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "6","mes": "JUNIO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "7","mes": "JULIO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "8","mes": "AGOSTO","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "9","mes": "SEPTIEMBRE","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "10","mes": "OCTUBRE","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "11","mes": "NOVIEMBRE","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null},
      {"orden": "12","mes": "DICIEMBRE","alicuota": null,"ingresos": null,"deducciones_exenciones": null,"base_imponible": null,"tasa_determinada": null,"cantidad_de_empleados": null,"total_segun_ingresos": null,"diferencia_hasta_cubrir_el_minimo": null}
  ]
}
export const convenioMultilateralDummy = {
    "inscripto_en_regimen": "","desarrollo_actividad_otras_jurisdicciones": "","nro_inscripcion": "",
    "coeficientes_distribucion": [
      {"orden": "1","jurisdiccion": "BUENOS AIRES","ingresos": null,"coeficiente_ingresos": null,"gastos": null,"coeficiente_gastos": null,"coeficiente_aplicacion": null},
      {"orden": "2","jurisdiccion": "CABA","ingresos": null,"coeficiente_ingresos": null,"gastos": null,"coeficiente_gastos": null,"coeficiente_aplicacion": null},
    ]
  }
export const pagosDirectosTasasDummy = [
    {"orden": "1","mes": "ENERO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "2","mes": "FEBRERO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "3","mes": "MARZO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "4","mes": "ABRIL","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "5","mes": "MAYO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "6","mes": "JUNIO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "7","mes": "JULIO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "8","mes": "AGOSTO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "9","mes": "SEPTIEMBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "10","mes": "OCTUBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "11","mes": "NOVIEMBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "12","mes": "DICIEMBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null}
]
export const determinacionDiferenciasDummy = [
    {"orden": "1","mes": "ENERO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "2","mes": "FEBRERO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "3","mes": "MARZO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "4","mes": "ABRIL","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "5","mes": "MAYO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "6","mes": "JUNIO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "7","mes": "JULIO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "8","mes": "AGOSTO","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "9","mes": "SEPTIEMBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "10","mes": "OCTUBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "11","mes": "NOVIEMBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null},
    {"orden": "12","mes": "DICIEMBRE","fecha_de_pago": "","nro_de_comprobante": "","importe": null}
]
export const actividadesDesarrolladasDummy = [
  {"orden": "1","codigo_de_actividad": "","descripcion": "","ingresos_brutos_anuales": null},
  {"orden": "2","codigo_de_actividad": "","descripcion": "","ingresos_brutos_anuales": null},
	{"orden": "3","codigo_de_actividad": "","descripcion": "","ingresos_brutos_anuales": null},
	{"orden": "4","codigo_de_actividad": "","descripcion": "","ingresos_brutos_anuales": null},
	{"orden": "5","codigo_de_actividad": "","descripcion": "","ingresos_brutos_anuales": null}
]
export const sucursalesFilialesDummy = {
  "posee_sucursales": "",
  "sucursales": [
    {"orden": 1,"calle": "","numero": "","localidad": "","partido": "",},
    {"orden": 2,"calle": "","numero": "","localidad": "","partido": "",},
    {"orden": 3,"calle": "","numero": "","localidad": "","partido": "",},
    {"orden": 4,"calle": "","numero": "","localidad": "","partido": "",},
    {"orden": 5,"calle": "","numero": "","localidad": "","partido": "",}
  ]
}

export const tributoPropiedadDummy = [
  {"orden": 1,"calle": "","numero": "","localidad": "","nro_de_partida": "","caracter": "","observaciones": ""},
  {"orden": 2,"calle": "","numero": "","localidad": "","nro_de_partida": "","caracter": "","observaciones": ""},
	{"orden": 3,"calle": "","numero": "","localidad": "","nro_de_partida": "","caracter": "","observaciones": ""},
	{"orden": 4,"calle": "","numero": "","localidad": "","nro_de_partida": "","caracter": "","observaciones": ""},
	{"orden": 5,"calle": "","numero": "","localidad": "","nro_de_partida": "","caracter": "","observaciones": ""},
]
