<template>
  <div class="main">

    <table class="grilla">
      <tr class="header-grilla">
        <td>Calle</td>
        <td>Número</td>
        <td>Localidad</td>
        <td>N° de Partida</td>
        <td>Carácter</td>
        <td>Observaciones</td>
      </tr>
      
      <tr v-for="(d,i) in data" v-bind:key="d.orden">
        <td><input type="text" v-model="data[i].calle" :class="{'input--error':i==0 && (data[i].calle == null || data[i].calle==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data[i].numero" :class="{'input--error':data[i].calle !='' &&  (data[i].numero == null || data[i].numero==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="text" v-model="data[i].localidad" :class="{'input--error':data[i].calle !='' &&  (data[i].localidad == null || data[i].localidad==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="text" v-model="data[i].nro_de_partida" :class="{'input--error':data[i].calle !='' &&  (data[i].nro_de_partida == null || data[i].nro_de_partida==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td>
          <select v-model="data[i].caracter" @change="updateData">
          <option>TITULAR</option>
          <option>LOCATARIO</option>
          </select>
        </td>
        <!-- <td><input type="text" v-model="data[i].observaciones" :disabled="props.disabled" @input="updateData"></td> -->
        <td>
          <textarea v-model="data[i].observaciones" :disabled="props.disabled" @input="updateData" rows="2" cols="50"></textarea>
        </td>
      </tr>
    </table>

  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
    console.log(props.initialData);
  })

  const updateData = () => {
    emit('change', data);
  };

</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input, select, textarea {
  background-color: white;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;;
}
select {
  background-color: white;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.input--error{
    border-color:red;
    border-style: solid;
}

</style>
