<template>
  <div class="main">
    <table class="grilla">
      <tr class="header-grilla">
        <td>MES</td>
        <td>Ingresos</td>
        <td>Deducciones y Exenciones</td>
        <td>Base imponible</td>
        <td>Tasa según alícuota</td> 
        <td>Dif h/ cubrir el mínimo</td>
        <td>Tasa Determinada</td>
        <td>Cantidad de Empleados </td>
      </tr>
      
      <tr v-for="(d,i) in data.tasas" v-bind:key="d.orden">
        <td>{{data.tasas[i].mes}}</td>
        <input type="text" :value="getMonthlyIncomes(data.tasas[i].mes)" disabled>
        <td><input type="number" v-model="data.tasas[i].deducciones_exenciones" :class="{'input--error':data.tasas[i].deducciones_exenciones == null || data.tasas[i].deducciones_exenciones===''}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.tasas[i].base_imponible" :class="{'input--error':data.tasas[i].base_imponible == null || data.tasas[i].base_imponible===''}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.tasas[i].total_segun_ingresos" :class="{'input--error':data.tasas[i].total_segun_ingresos == null || data.tasas[i].total_segun_ingresos===''}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.tasas[i].diferencia_hasta_cubrir_el_minimo" :class="{'input--error':data.tasas[i].diferencia_hasta_cubrir_el_minimo == null || data.tasas[i].diferencia_hasta_cubrir_el_minimo===''}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.tasas[i].tasa_determinada" :class="{'input--error':data.tasas[i].tasa_determinada == null || data.tasas[i].tasa_determinada===''}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data.tasas[i].cantidad_de_empleados" :class="{'input--error':data.tasas[i].cantidad_de_empleados == null || data.tasas[i].cantidad_de_empleados===''}" :disabled="props.disabled" @input="updateData"></td>
      </tr>

      <tr class="totales">
          <td></td>
          <td>{{ totalIngresos }}</td>
          <td></td>
          <td>{{ totalBaseImponible }}</td>
          <td></td>
          <td></td>
          <td>{{ totalTasaDeterminada }}</td>
          <td></td>
        </tr>

    </table> 

  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits, computed } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
  })

  const updateData = () => {
    emit('change', data);
  };

  const totalBaseImponible = computed(() => {
    return data.value.tasas.reduce((acc, item) => {
      const value = item.base_imponible !== null && item.base_imponible !== '' ? parseFloat(item.base_imponible) : 0;
      return acc + value;
    }, 0);
  });

  const totalTasaDeterminada = computed(() => {
    return data.value.tasas.reduce((acc, item) => {
      const value = item.tasa_determinada !== null && item.tasa_determinada !== '' ? parseFloat(item.tasa_determinada) : 0;
      return acc + value;
    }, 0);
  });

  const monthlyIncomes = computed(() => {
    const monthlyIncomesMap = {};
    data.value.tasas_rubros.forEach(item => {
      const value = item.ingresos !== null && item.ingresos !== '' ? parseFloat(item.ingresos) : 0;
      monthlyIncomesMap[item.mes] = (monthlyIncomesMap[item.mes] || 0) + value;
    });
    return monthlyIncomesMap;
  });

  const getMonthlyIncomes = (mes) => {
    return monthlyIncomes.value[mes] || 0;
  };

  const totalIngresos = computed(() => {
    return data.value.tasas_rubros.reduce((acc, item) => {
      const value = item.ingresos !== null && item.ingresos !== '' ? parseFloat(item.ingresos) : 0;
      return acc + value;
    }, 0);
  });

</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;;
}
.totales {
  background-color: lightgrey;
  color: black;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.input--error{
    border-color:red;
    border-style: solid;
}

</style>
