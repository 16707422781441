<template>
  <div class="main">

    <table class="grilla">
      <tr class="header-grilla">
        <td>MES</td>
        <td>Fecha de Pago</td>
        <td>N° de Comprobante</td>
        <td>Importe</td>
      </tr>
      
      <tr v-for="(d,i) in data" v-bind:key="d.orden">
        <td>{{data[i].mes}}</td>
        <td><input type="date" v-model="data[i].fecha_de_pago" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="text" v-model="data[i].nro_de_comprobante" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data[i].importe" :disabled="props.disabled" @input="updateData"></td>
      </tr>

      <tr class="totales">
        <td></td>
        <td></td>
        <td></td>
        <td>{{ totalImporte }}</td>
      </tr>
    </table>

  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits, computed } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
    console.log(props.initialData);
  })

  const updateData = () => {
    emit('change', data);
  };

  const totalImporte = computed(() => {
    return data.value.reduce((acc, item) => {
      const value = item.importe !== null && item.importe !== '' ? parseFloat(item.importe) : 0;
      return acc + value;
    }, 0);
  });

</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;;
}
.totales {
  background-color: lightgrey;
  color: black;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
</style>
