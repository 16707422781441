<template>
  <div class="main">

    <table class="grilla">
      <tr class="header-grilla">
        <td>Código de Actividad</td>
        <td>Descripción</td>
        <td>Ingresos Brutos Anuales</td>
      </tr>
      
      <tr v-for="(d,i) in data" v-bind:key="d.orden">
        <td><input type="text" v-model="data[i].codigo_de_actividad" :class="{'input--error':i==0 && (data[i].codigo_de_actividad == null || data[i].codigo_de_actividad==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="text" v-model="data[i].descripcion" :class="{'input--error':data[i].codigo_de_actividad !='' &&  (data[i].descripcion == null || data[i].descripcion==='')}" :disabled="props.disabled" @input="updateData"></td>
        <td><input type="number" v-model="data[i].ingresos_brutos_anuales" :class="{'input--error':data[i].codigo_de_actividad !='' &&  (data[i].ingresos_brutos_anuales == null || data[i].ingresos_brutos_anuales==='')}" :disabled="props.disabled" @input="updateData"></td>
      </tr>

      <tr class="totales">
        <td></td>
        <td></td>
        <td>{{totalIngresos}}</td>
      </tr>
    </table>

  </div>
</template>

<script setup>
  import { defineProps, onMounted, ref, defineEmits, computed } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change']);

  const data = ref(props.initialData);

  onMounted(async () => {
    console.log(props.initialData);
  })

  const updateData = () => {
    emit('change', data);
  };

  const totalIngresos = computed(() => {
    return data.value.reduce((acc, item) => {
      const value = item.ingresos_brutos_anuales !== null && item.ingresos_brutos_anuales !== '' ? parseFloat(item.ingresos_brutos_anuales) : 0;
      return acc + value;
    }, 0);
  });

  // const isPresentado = computed(() => {
  //   return props.estado === 'Presentado';
  // });

</script>

<style scoped>
.main {
  color: black;
  display: grid;
  place-items: center;
}
input {
  background-color: white;
}
.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.grilla {
  padding: 5px;
}
.totales {
  background-color: lightgrey;
  color: black;
}
.presentado input {
  background-color: transparent;
  border: none;
  pointer-events: none;
}
.input--error{
    border-color:red;
    border-style: solid;
}

</style>
