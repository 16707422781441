<template>
  <div class="header">
    <div class="titulo">Tasa por Inspección de Seguridad e Higiene</div>
    <br>
    <table width="90%">
      <tr>
        <td><b>Cuenta Corriente</b></td>
        <td>{{data.cc}}</td>
        <td><b>CUIT</b></td>
        <td>{{data.cuit}}</td>
        <td width="10%"></td>
        <td><b>DDJJ Año</b></td>
        <td>{{data.anio}}</td>
        <td><b>Estado</b></td>
        <td>{{data.estado}}</td>
      </tr>
      <tr>
        <td><b>Razón Social</b></td>
        <td colspan="3">{{data.razon_social}}</td>
        <td width="10%"></td>
        <td><b>Tipo</b></td>
        <td colspan="3"> {{ data.tipo=='0' ? 'ORIGINAL' : 'RECTIFICATIVA ' + data.tipo}}</td>
      </tr>
    </table>
  </div>
</template>

<script setup>
  import { defineProps, ref } from "vue";

  const props = defineProps({
    initialData: {
      type: Object,
      required: true,
    },
  });

  //const emit = defineEmits(['changed']);

  const data = ref(props.initialData);

  // const updateData = () => {
  //   emit('change', data);
  // };

</script>

<style scoped>
.header {
  padding: 13px;
  background-color: #DDDDDD;
}
.titulo {
  font-size: x-large;
  font-weight: bold;
  color: black;
}
td {
  text-align: left;
}
input {
  background-color: white;
}
</style>
